import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ApiResponse } from '@classes/api-response';
import { Order } from '@models/order';
import { Patient } from '@models/patient';
import { Prescription } from '@models/prescription';
import { OrderService } from '@services/order.service';
import { PatientService } from '@services/patient.service';
import { catchError, concatMap, EMPTY, map, of, switchMap } from 'rxjs';

export interface PatientDialogData {
  patient: Patient;
  message: string;
}

export interface FlatPrescription {
  label: string;
  value: string;
}

export interface PrescriptionDisplayData {
  id: string;
  date: Date;
  type: string;
  left: Array<FlatPrescription>;
  right: Array<FlatPrescription>;
}

@Component({
  selector: 'app-patient-dialog',
  templateUrl: './patient-dialog.component.html',
  styleUrls: ['./patient-dialog.component.css'],
})
export class PatientDialogComponent implements OnInit {
  createPatientError: any;
  updatePatientError: any;
  selectedOrders: Record<string, boolean> = {};
  selectedPrescriptions: Record<string, boolean> = {};
  prescriptionData: Array<PrescriptionDisplayData> = [];
  os: Array<Order> = [];

  get orders(): Array<Order> {
    return (this.data.patient.orders as Array<Order>) ?? [];
  }

  private get orderIds(): Array<string> {
    return this.orders.map((o: Order) => o._id);
  }

  get prescriptions(): Array<Prescription> {
    return (this.data.patient.prescriptions as Array<Prescription>) ?? [];
  }

  private get prescriptionIds(): Array<string> {
    return this.prescriptions.map((p: Prescription) => p._id);
  }

  constructor(
    public dialogRef: MatDialogRef<PatientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatientDialogData,
    private patientService: PatientService,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.setEyePrescriptionArray();
    this.getPatientOrders();
  }

  getPrescriptionsData(
    prescription: Prescription,
    key: 'left' | 'right'
  ): Array<{ label: string; value: string }> {
    return Object.entries(prescription[key]).reduce((lacc, [label, value]) => {
      return [
        ...lacc,
        {
          label,
          value,
        },
      ];
    }, []);
  }

  getPatientOrders() {
    this.orderService
      .getPatientOrders(this.data.patient._id, 'orders')
      .pipe(
        map((orders) =>
          orders.sort(
            (a, b) =>
              new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime()
          )
        )
      )
      .subscribe((res: Order[]) => {
        this.os = res;
      });
  }

  setEyePrescriptionArray(): void {
    this.prescriptionData = this.prescriptions.reduce((acc, p) => {
      const left = this.getPrescriptionsData(p, 'left');
      const right = this.getPrescriptionsData(p, 'right');
      return [
        ...acc,
        {
          id: p._id,
          date: new Date(p.dateAdded),
          type: p.type,
          left,
          right,
        },
      ];
    }, []);
  }

  selectOrder(id: string): void {
    if (this.selectedOrders[id]) {
      const { [id]: _, ...rest } = this.selectedOrders;
      this.selectedOrders = rest;
      return;
    }
    this.selectedOrders = { ...this.selectedOrders, [id]: true };
  }

  selectPrescription(id: string): void {
    if (this.selectedPrescriptions[id]) {
      const { [id]: _, ...rest } = this.selectedPrescriptions;
      this.selectedPrescriptions = rest;
      return;
    }
    this.selectedPrescriptions = { ...this.selectedPrescriptions, [id]: true };
  }

  createPatient() {
    const { _id, firstName, patientNotes, ...rest } = this.data.patient;
    const patient = {
      ...rest,
      firstName: `${firstName} COPY`,
      alertNotes: true,
      patientNotes: `<p>*** PATIENT COPIED ***</p><p>*** Please update patient data after duplication ***</p>${
        patientNotes ?? ''
      }`,
      orders: Object.keys(this.selectedOrders),
      prescriptions: Object.keys(this.selectedPrescriptions),
      favoriteLines: [],
    };
    return this.patientService.copyPatient(patient).pipe(
      catchError((err) => {
        this.dialogRef.close(false);
        return EMPTY;
      })
    );
  }

  updateExistingPatient() {
    const patient = {
      ...this.data.patient,
      orders: this.orderIds.filter((id) => !this.selectedOrders[id]),
      prescriptions: this.prescriptionIds.filter(
        (id) => !this.selectedPrescriptions[id]
      ),
    };
    this.patientService
      .updatedCopiedPatient(patient)
      .pipe(
        catchError((err) => {
          this.dialogRef.close(false);
          return EMPTY;
        })
      )
      .subscribe();
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.createPatient()
      .pipe(
        concatMap(({ patient }) => {
          this.updateExistingPatient();
          return of(patient);
        })
      )
      .subscribe((patient) => {
        this.dialogRef.close(patient);
      });
  }
}
