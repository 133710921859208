export class Patient {
  _id: string;
  store: string;
  prefix: string;
  patientNumber: string;
  dateAdded: string;
  dateUpdated: string;
  dob: string;
  discountStatus: string;
  storeCredit: number;
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  phone2: string;
  cell: string;
  email: string;
  since: string;
  badAddress: string;
  occupation: string;
  interests: string;
  favoriteLines: string[];
  familyMembers: string;
  beBackList: string;
  howDidYouFindUs: string;
  howDidYouFindUsOther: string;
  patientNotes: string;
  sleep: boolean;
  optOut: boolean;
  doctorName: string;
  doctorPhone: string;
  doctorFax: string;
  doctorEmail: string;
  _doctorId: string;
  lastExam: string;
  callForRx: boolean;
  //prescriptions: string;
  prescriptionNotes: string;
  //prescriptionImageIDs: string;
  //images: Object[];
  //images2: Object[];
  //imageData: Object[];
  orders: Object[];
  prescriptions: Object[];
  internalPatientId?: string;
  from2020Now?: boolean;
  alertNotes?: boolean;

  constructor() {
    this._id = '';
    this.store = '';
    this.prefix = '';
    this.patientNumber = '';
    this.dateAdded = '';
    this.dateUpdated = '';
    this.dob = '';
    this.discountStatus = '';
    this.storeCredit = 0;
    this.firstName = '';
    this.lastName = '';
    this.address = '';
    this.address2 = '';
    this.city = '';
    this.state = '';
    this.zip = '';
    this.phone = '';
    this.phone2 = '';
    this.cell = '';
    this.email = '';
    this.since = '';
    this.badAddress = '';
    this.occupation = '';
    this.interests = '';
    this.favoriteLines = [];
    this.familyMembers = '';
    this.beBackList = '';
    this.howDidYouFindUs = '';
    this.howDidYouFindUsOther = '';
    this.patientNotes = '';
    this.sleep = false;
    this.optOut = false;
    this._doctorId = '';
    this.doctorName = '';
    this.doctorPhone = '';
    this.doctorFax = '';
    this.doctorEmail = '';
    this.lastExam = '';
    this.callForRx = false;
    //this.prescriptions = '';
    this.prescriptionNotes = '';
    //this.prescriptionImageIDs = '';
    //this.images = [];
    //this.images2 = [];
    //this.imageData = [];
    this.orders = [];
    this.prescriptions = [];
    this.internalPatientId = '';
    this.from2020Now = false;
    this.alertNotes = false;
  }
}
