<mat-dialog-content>
  <h2>Copy Patient</h2>
  <p>
    This will copy the data for {{ data.patient.firstName }}
    {{ data.patient.lastName }} to a new patient. Select the prescriptions,
    order and favorite lines to associate with the patient copy. Note: orders
    and prescriptions will removed from the current patient.
  </p>

  <div class="d-flex flex-column" *ngIf="os.length">
    <label>Select orders to associate with new patient</label>
    <div class="d-flex orders">
      <div *ngFor="let order of os" class="ao-checkbox order odd-color">
        <span><label>Invoice#: </label>{{ order.invoiceNumber }}</span>
        <input
          type="checkbox"
          [checked]="selectedOrders[order._id]"
          (change)="selectOrder(order._id)"
        />
      </div>
    </div>
  </div>

  <div class="d-flex-full flex-column" *ngIf="prescriptionData.length">
    <label>Select prescriptions to associate with new patient</label>
    <div class="d-flex-full prescriptions">
      <div
        *ngFor="let p of prescriptionData"
        class="d-flex flex-column odd-color"
        style="width: 33%"
      >
        <div class="eye-type ao-checkbox prescription">
          <label>{{ p.type }} | {{ p.date | date : 'MM/dd/yy' }}</label>
          <input
            type="checkbox"
            [checked]="selectedPrescriptions[p._id]"
            (change)="selectPrescription(p.id)"
          />
        </div>
        <!-- <div class="d-flex">
            <div class="d-flex flex-column eye">
              <h5>Left</h5>
              <div *ngFor="let entry of p.left">
                <label>{{ entry.label }}</label>
                <p>{{ entry.value }}</p>
              </div>
            </div>
            <div class="d-flex flex-column eye">
              <h5>Right</h5>
              <div *ngFor="let entry of p.right">
                <label>{{ entry.label }}</label>
                <p>{{ entry.value }}</p>
              </div>
            </div>
          </div> -->
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="confirm()">CONFIRM</button>
  <button mat-raised-button (click)="cancel()">CANCEL</button>
</mat-dialog-actions>
