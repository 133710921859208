<div [class.post-it]="data.postIt">
<mat-dialog-content class="XXXXXX">
  <div [innerHTML]="data.message"></div>
</mat-dialog-content>
<mat-dialog-actions>
  <div style="text-align: center; width: 100%">
    <button mat-raised-button (click)="ok()">{{ data.confirmText ?? 'OK' }}</button>
  <button *ngIf="!data.hideCancel" mat-raised-button (click)="cancel()">{{ data.cancelText ?? 'CANCEL' }}</button>
  </div>
</mat-dialog-actions>
</div>

